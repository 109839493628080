import axios from "axios";
import { ListObject } from "../models/ListObject";
import { TaskWithRole } from "../models/TaskWithRole";
import { gritifyApiUrl, publicApiUrl } from "./ServiceHelper";
import {
  WorkTitleDto,
  TalentLocation,
  GetRolesDto,
  CompanyListObject,
  EducationDomainListObject,
  GetWeightedSkillsForRoleDto,
  SkillRecommendation,
  PublishedCompoanyProfileDto,
  TalentJobOffer,
  ExternalTalentProfileToAdd,
  ExternalTalentQuestionsObject,
  BranchLookupDto,
  GetFriendInvitationDataDto,
  LocationDto,
  GetLocationsForRecruitmentMatchingDto,
  GetDeclineReasonsDto,
  OpennessSettings,
  opennesSettingsSchema,
  CompanyGDPRTexts,
  linkedInCompanySchema,
  linkedInCompany,
  GetCompanyInformationWithiScraperDto,
  getCompanyInformationWithiScraperSchema,
  IndustryLookupDto,
} from "~/models/types";

const apiClient = axios.create({
  baseURL: "https://gritifysearch.search.windows.net/indexes/",
  headers: {
    "api-key": "9AA2C2D1B56ACF0175E1D2A45E4FABFC",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

export default class LookupDataService {
  static getCitiesPublic(lang: string): Promise<TalentLocation[]> {
    return axios
      .get<TalentLocation[]>(`${publicApiUrl}/BasicData/Locations?lang=${lang}`)
      .then(r =>
        r.data
          .filter(l => !!l.text)
          .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
      );
  }

  static getLocationsForRecruitmentMatching(v: {
    token: string;
  }): Promise<LocationDto[]> {
    return axios
      .get<GetLocationsForRecruitmentMatchingDto[]>(
        `${gritifyApiUrl}/GetLocationsForRecruitmentMatching`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r =>
        r.data.map(l => {
          let result: LocationDto | null = null;
          if (l.locationType === "Remote") {
            result = {
              type: "Remote",
              id: l.id,
            };
          } else {
            result = {
              type: "Other",
              id: l.id,
              polygon: l.geometry.coordinates[0]!,
            };
          }
          return result;
        })
      );
  }

  static getRolesPublic(lang: string): Promise<GetRolesDto[]> {
    return axios
      .get<GetRolesDto[]>(`${publicApiUrl}/BasicData/Roles?lang=${lang}`)
      .then(r => r.data);
  }

  static tryMatchUndefinedTitleToRole(v: {
    titleText: string;
    token: string;
  }): Promise<string[]> {
    return axios
      .get<string[]>(`${gritifyApiUrl}/TryMatchUndefinedTitleToRole`, {
        params: {
          titleText: v.titleText,
        },
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => r.data);
  }

  static getRoleGroupSorting(lang: string): Promise<string[]> {
    return axios
      .get<string[]>(`${publicApiUrl}/BasicData/JobCategory?lang=${lang}`)
      .then(r => r.data);
  }

  static async getDeclineReasonsPublic(
    lang: string
  ): Promise<GetDeclineReasonsDto[]> {
    try {
      const result = await axios
        .get<GetDeclineReasonsDto[]>(
          `${publicApiUrl}/BasicData/DeclineReasons?lang=${lang}`
        )
        .then(r => r.data);

      return result;
    } catch (error) {
      return [];
    }
  }

  static getBranchesPublic(lang: string): Promise<BranchLookupDto[]> {
    return axios
      .get<BranchLookupDto[]>(`${publicApiUrl}/BasicData/Branches?lang=${lang}`)
      .then(r => r.data);
  }

  static getAllIndustriesPublic(lang: string): Promise<IndustryLookupDto[]> {
    return axios
      .get<IndustryLookupDto[]>(
        `${publicApiUrl}/GetAllIndustriesPublic?lang=${lang}`
      )
      .then(r => r.data);
  }

  static getLanguagesPublic(lang: string): Promise<ListObject[]> {
    return axios
      .get(`${publicApiUrl}/BasicData/Language?lang=${lang}`)
      .then(r => r.data);
  }

  static getTitleByName(v: {
    titleName: string;
  }): Promise<WorkTitleDto | null> {
    return axios
      .get<WorkTitleDto | null>(
        `${publicApiUrl}/BasicData/TitleByName?titleName=${encodeURIComponent(
          v.titleName
        )}`
      )
      .then(r =>
        r.data
          ? {
              ...r.data,
              pickFromSelectedGroups:
                typeof r.data.pickFromSelectedGroups === "boolean"
                  ? r.data.pickFromSelectedGroups
                  : r.data.text === "Controller",
            }
          : null
      );
  }

  static getCompanyProfileById(
    companyId: string
  ): Promise<PublishedCompoanyProfileDto> {
    return axios
      .get<PublishedCompoanyProfileDto>(
        `${publicApiUrl}/GetPublishedProfile?companyId=${companyId}`
      )
      .then(r => {
        return {
          ...r.data,
          ingress: r.data.ingress || "",
          teamVisible: r.data.teamVisible || false,
          team: r.data.team.map((t, i) => ({
            ...t,
            id: i.toString(),
            isRemoved: false,
          })),
          offices: r.data.offices.map((o, i) => ({
            ...o,
            id: i.toString(),
            isRemoved: false,
          })),
        };
      });
  }

  static getExternalJobOffer(v: {
    companyId: string;
    recruitmentId: string;
  }): Promise<TalentJobOffer> {
    return axios
      .get<TalentJobOffer>(
        `${publicApiUrl}/GetExternalJobOffer?companyId=${v.companyId}&recruitmentId=${v.recruitmentId}`
      )
      .then(r => r.data);
  }

  static upsertExternalTalentProfile(v: {
    talentProfile: ExternalTalentProfileToAdd;
  }): Promise<TalentJobOffer> {
    return axios
      .post<TalentJobOffer>(
        `${publicApiUrl}/UpsertExternalTalentProfile`,
        v.talentProfile
      )
      .then(r => r.data);
  }

  static uploadCV(v: {
    email: string;
    recruitmentId: string;
    file: any;
  }): Promise<void> {
    const formData = new FormData();
    formData.append("file", v.file, v.file.name);
    return axios
      .post<void>(
        `${publicApiUrl}/UploadDocument?email=${v.email}&recruitmentId=${v.recruitmentId}&documentType=1`,
        formData
      )
      .then(r => r.data);
  }

  static uploadDocument(v: {
    email: string;
    recruitmentId: string;
    file: any;
  }): Promise<void> {
    const formData = new FormData();
    formData.append("file", v.file, v.file.name);
    return axios
      .post<void>(
        `${publicApiUrl}/UploadDocument?email=${v.email}&recruitmentId=${v.recruitmentId}&documentType=2`,
        formData
      )
      .then(r => r.data);
  }

  static getInfoFromExternalFormLink(v: {
    linkPart: string;
  }): Promise<{ recruitmentId: string; companyId: string } | null> {
    return axios
      .get<{ recruitmentId: string; companyId: string } | null>(
        `${publicApiUrl}/GetExternalFormDataByFormId?formId=${v.linkPart}`
      )
      .then(r => r.data);
  }

  static getExternalTalentQuestions(v: {
    recruitmentId: string;
  }): Promise<ExternalTalentQuestionsObject | null> {
    return axios
      .get<ExternalTalentQuestionsObject | null>(
        `${publicApiUrl}/GetExternalTalentQuestions?recruitmentId=${v.recruitmentId}`
      )
      .then(r => r.data);
  }

  static getNeighbouringSkills(v: {
    skillIds: string[];
    lang: string;
  }): Promise<ListObject[]> {
    return axios
      .post<ListObject[]>(`${publicApiUrl}/GetNeighbouringSkills`, {
        skillIds: v.skillIds,
        lang: v.lang,
      })
      .then(r => r.data);
  }

  static getSkillRecommendations(v: {
    roleId: string;
    lang: string;
    talentRoles: GetWeightedSkillsForRoleDto[];
  }): Promise<SkillRecommendation[]> {
    return axios
      .post<SkillRecommendation[]>(`${publicApiUrl}/GetSkillRecommendations`, {
        rolId: v.roleId,
        lang: v.lang,
        talentRoles: v.talentRoles,
      })
      .then(r => r.data);
  }

  static getDomainRecommendations(v: {
    roleId: string;
    schoolId: string;
  }): Promise<string[]> {
    return axios
      .get<string[]>(
        `${publicApiUrl}/GetDomainRecommendations?roleId=${v.roleId}&schoolId=${v.schoolId}`
      )
      .then(r => r.data);
  }

  static getAllTasksPublic(lang: string): Promise<TaskWithRole[]> {
    return axios
      .get(`${publicApiUrl}/BasicData/Tasks?lang=${lang}`)
      .then(r => r.data);
  }

  static getEducationDomainsPublic(
    lang: string
  ): Promise<EducationDomainListObject[]> {
    return axios
      .get<EducationDomainListObject[]>(
        `${publicApiUrl}/BasicData/Domains?lang=${lang}`
      )
      .then(r =>
        r.data
          .filter(i => !!i.text)
          .map(i => ({ ...i, category: i.category || "Annat" }))
      );
  }

  static async getFromAzureSearchForLang<T>(
    searchTerm: string,
    collection: string,
    lang: string
  ): Promise<T[]> {
    const searchTermString = searchTerm || "";

    let searchTermWithoutDot = searchTermString;
    if (searchTermWithoutDot.startsWith(".")) {
      searchTermWithoutDot = searchTermWithoutDot.substr(1);
    }
    const searchTermListJoin = searchTermWithoutDot
      .replace(/\+|-|!|#|\(|\)|\{|\}|&|\[|\]|\^|"|\/|~|\*|\?|:|\\|,/gi, " ")
      .split(" ")
      .map((t, i, arr) => {
        const isLast = arr.length === i + 1;
        return `${t}${isLast ? "*" : ""}`;
      })
      .filter(t => t.length && t !== "*")
      .join(" AND ");

    if (!searchTermListJoin) {
      return [];
    }
    const schoolsRespons = await apiClient.get<{
      value: Array<T & { id: string } & { [key: string]: string }>;
    }>(
      `${collection}/docs?api-version=2019-05-06&search=${searchTermListJoin}&searchMode=all&querytype=full`
    );

    return this.getMoreResults({
      azyreResponse: schoolsRespons,
      textMapper: x => x[lang] ?? "",
      searchTermString,
    });
  }

  static async getFromAzureSearch(
    searchTerm: string,
    collection: string
  ): Promise<ListObject[]> {
    const searchTermListJoin =
      searchTerm.length <= 2
        ? searchTerm
        : searchTerm
            .replace(
              /\+|-|!|#|\(|\)|\{|\}|\[|\]|\^|"|\/|~|\*|\?|:|&|\\|,/gi,
              " "
            )
            .split(" ")
            .map((t, i, arr) => {
              const isLast = arr.length === i + 1;
              return `${t}${isLast ? "*" : ""}`;
            })
            .filter(t => t.length && t !== "*")
            .join(" AND ");

    const schoolsRespons = await apiClient.get<{ value: ListObject[] }>(
      `${collection}/docs?api-version=2019-05-06&search=${searchTermListJoin}&searchMode=all&querytype=full`
    );

    return this.getMoreResults({
      azyreResponse: schoolsRespons,
      searchTermString: searchTerm || "",
      textMapper: x => x.text,
    });
    // const schoolsRespons = await axios.get(
    //   `https://gritifysearch.search.windows.net/indexes/${collection}/docs/autocomplete?api-version=2019-05-06&search=${searchTerm}`,
    //   {
    //     headers: {
    //       "api-key": "9AA2C2D1B56ACF0175E1D2A45E4FABFC",
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     timeout: 30000,
    //   }
    // );
    // return schoolsRespons.data.value
    //   .map(x => ({
    //     id: x.id,
    //     text: x.text,
    //   }))
    //   .sort((a: ListObject, b: ListObject) => {
    //     const aStartsWith = a.text
    //       .toLowerCase()
    //       .startsWith(searchTerm.toLowerCase());
    //     const bStartsWith = b.text
    //       .toLowerCase()
    //       .startsWith(searchTerm.toLowerCase());
    //     return aStartsWith < bStartsWith
    //       ? 1
    //       : aStartsWith > bStartsWith
    //       ? -1
    //       : 0;
    //   });
  }

  static getCompaniesBySearchTerm(
    searchTerm: string,
    lang: string
  ): Promise<CompanyListObject[]> {
    const collection =
      process.env.ENVIRONMENT === "Production"
        ? "company"
        : process.env.ENVIRONMENT === "Stage"
        ? "company-stage"
        : "company-test";
    return this.getFromAzureSearchForLang(
      searchTerm.replace(/ ab/gi, ""),
      collection,
      lang
    );
  }

  private static async getMoreResults<T>(v: {
    searchTermString: string;
    textMapper: (v: T) => string;
    azyreResponse: {
      data: {
        ["@odata.nextLink"]?: string;
        value: T[];
      };
    };
  }): Promise<T[]> {
    const maxRefetches = 8;
    let refetches = 0;

    let azureResponseCopy = { ...v.azyreResponse };

    while (
      !azureResponseCopy.data.value.some(
        x =>
          (v.textMapper(x) || "").toLocaleLowerCase() ===
          v.searchTermString.toLocaleLowerCase()
      ) &&
      azureResponseCopy.data["@odata.nextLink"] &&
      refetches <= maxRefetches
    ) {
      refetches++;
      const nextResponse = await apiClient.get<{
        ["@odata.nextLink"]?: string;
        value: T[];
      }>(azureResponseCopy.data["@odata.nextLink"]);

      azureResponseCopy = {
        ...nextResponse,
        data: {
          ...nextResponse.data,
          value: [...azureResponseCopy.data.value, ...nextResponse.data.value],
        },
      };
    }

    const searchTermStringTrimmed = (v.searchTermString || "").trim();

    return azureResponseCopy.data.value
      .map(x => ({
        ...x,
        text: v.textMapper(x),
      }))
      .sort((a, b) => {
        const aIsMatchStart =
          (a.text || "").toLocaleLowerCase() ===
          searchTermStringTrimmed.toLocaleLowerCase();
        const bIsMatchStart =
          (b.text || "").toLocaleLowerCase() ===
          searchTermStringTrimmed.toLocaleLowerCase();
        if (aIsMatchStart !== bIsMatchStart) {
          return aIsMatchStart ? -1 : 1;
        } else {
          const aIsMatch = (a.text || "")
            .toLocaleLowerCase()
            .startsWith(searchTermStringTrimmed.toLocaleLowerCase());
          const bIsMatch = (b.text || "")
            .toLocaleLowerCase()
            .startsWith(searchTermStringTrimmed.toLocaleLowerCase());

          if (aIsMatch !== bIsMatch) {
            return aIsMatch ? -1 : 1;
          } else {
            return 0;
          }
        }
      });
  }

  static async getWorkTitlesBySearchTerm(
    searchTerm: string
  ): Promise<WorkTitleDto[]> {
    const collection =
      process.env.ENVIRONMENT === "Production"
        ? "title"
        : process.env.ENVIRONMENT === "Stage"
        ? "title-stage"
        : "title-test";

    const searchTermString = searchTerm || "";

    let searchTermWithoutDot = searchTermString;
    if (searchTermWithoutDot.startsWith(".")) {
      searchTermWithoutDot = searchTermWithoutDot.substr(1);
    }
    const searchTermListJoin =
      searchTermWithoutDot.length <= 2
        ? searchTermWithoutDot.replace(
            /\+|-|!|#|\(|\)|\{|\}|\[|\]|\^|"|\/|~|\*|\?|:|&|\\|,/gi,
            " "
          )
        : searchTermWithoutDot
            .replace(
              /\+|-|!|#|\(|\)|\{|\}|\[|\]|\^|"|\/|~|\*|\?|:|&|\\|,/gi,
              " "
            )
            .split(" ")
            .map((t, i, arr) => {
              const isLast = arr.length === i + 1;
              return `${t}${isLast ? "*" : ""}`;
            })
            .filter(t => t.length && t !== "*")
            .join(" AND ");

    const titlesResponse = await apiClient.get<{
      ["@odata.nextLink"]?: string;
      value: WorkTitleDto[];
    }>(
      `${collection}/docs?api-version=2019-05-06&search=${searchTermListJoin}&searchMode=all&querytype=full`
    );

    const result = await this.getMoreResults({
      searchTermString,
      azyreResponse: titlesResponse,
      textMapper: x => x.text,
    });

    return result.map(x => ({
      ...x,

      pickFromSelectedGroups:
        typeof x.pickFromSelectedGroups === "boolean"
          ? x.pickFromSelectedGroups
          : x.text === "Controller",
    }));
  }

  static getTitleById(titleId: string): Promise<WorkTitleDto> {
    return axios
      .get<WorkTitleDto>(`${publicApiUrl}/BasicData/Title?titleId=${titleId}`)
      .then(r => {
        return {
          ...r.data,
          roles: r.data.roles || [],
          pickFromSelectedGroups:
            typeof r.data.pickFromSelectedGroups === "boolean"
              ? r.data.pickFromSelectedGroups
              : r.data.text === "Controller",
        };
      });
  }

  static getTitlesByIds(v: {
    titleIds: string[];
    accessToken: string;
  }): Promise<WorkTitleDto[]> {
    return axios
      .post<WorkTitleDto[]>(
        `${gritifyApiUrl}/GetTitlesByIds`,
        {
          titleIds: v.titleIds,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getSkillsBySearchTerm(
    searchTerm: string,
    lang: string
  ): Promise<ListObject[]> {
    const collection =
      process.env.ENVIRONMENT === "Production"
        ? "skills"
        : process.env.ENVIRONMENT === "Stage"
        ? "skills-stage"
        : "skills-test";
    return this.getFromAzureSearchForLang(searchTerm, collection, lang);
  }

  static async getSchoolsBySearchTerm(
    searchTerm: string
  ): Promise<ListObject[]> {
    const collection =
      process.env.ENVIRONMENT === "Production"
        ? "school"
        : process.env.ENVIRONMENT === "Stage"
        ? "school-stage"
        : "school-test";
    const schools = await this.getFromAzureSearch(searchTerm, collection);
    return schools;
  }

  static GetFriendInvitationData(v: {
    inviterId: string;
    recruitmentId: string | null;
  }): Promise<GetFriendInvitationDataDto | null> {
    return axios
      .get<GetFriendInvitationDataDto | null>(
        `${publicApiUrl}/GetFriendInvitationData?inviterId=${v.inviterId}&recruitmentId=${v.recruitmentId}`
      )
      .then(r => r.data);
  }

  public static getOpennesSettings(v: {
    token: string;
  }): Promise<OpennessSettings | null> {
    return axios
      .get<unknown>(`${gritifyApiUrl}/GetOpennesSettings`, {
        headers: {
          authorization: `Bearer ${v.token}`,
        },
      })
      .then(x => {
        const hej = opennesSettingsSchema.safeParse(x.data);

        if (hej.success) {
          return hej.data;
        }
        console.log(hej.error);
        return null;
      });
  }

  public static getCompanyGDPRTexts(v: {
    companyId: string;
  }): Promise<CompanyGDPRTexts | null> {
    return axios
      .get<CompanyGDPRTexts | null>(`${publicApiUrl}/GetCompanyGDPRTexts`, {
        params: {
          companyId: v.companyId,
        },
      })
      .then(x => x.data);
  }

  static getIsNoString<T>(v: string | T): v is T {
    return typeof v !== "string";
  }

  public static async getLinkedInCompaniesBySearchTermAndLogos(v: {
    token: string;
    text: string;
  }) {
    const result = await this.getLinkedInCompaniesBySearchTerm({
      keyword: v.text,
      token: v.token,
    });

    const top3Results = result
      .filter(x => x.logo_url !== null && x.description !== null)
      .slice(0, 3);

    const extendadedDataPromises = top3Results.map(company => {
      return this.getCompanyInformationWithiScraper({
        linkedinUrl: company.linkedin_url,
        token: v.token,
      });
    });

    const extendedDatas = await Promise.all(extendadedDataPromises);

    const resultWithExtendedData: linkedInCompany[] = top3Results.flatMap(
      company => {
        const extendedData = extendedDatas
          .filter(this.getIsNoString)
          .find(x => x.details.company_id.toString() === company.company_id);

        if (!extendedData) {
          return [];
        }
        return [
          {
            ...company,
            logo_url: extendedData?.details.images.logo ?? null,
          },
        ];
      }
    );
    return resultWithExtendedData;
  }

  public static getLinkedInCompaniesBySearchTerm(v: {
    keyword: string;
    token: string;
  }): Promise<linkedInCompany[]> {
    return axios
      .post<unknown[]>(
        `${gritifyApiUrl}/GetLinkedInCompaniesBySearchTerm`,
        {
          keyword: v.keyword,
        },
        {
          headers: {
            authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => {
        return x.data.map(d => {
          const result = linkedInCompanySchema.parse(d);

          return result;
        });
      });
  }

  public static async getCompanyInformationWithiScraper(v: {
    linkedinUrl: string;
    token: string;
  }): Promise<GetCompanyInformationWithiScraperDto> {
    try {
      const result = await axios
        .post<unknown>(
          `${gritifyApiUrl}/GetCompanyInformationWithiScraper`,
          {
            linkedinUrl: v.linkedinUrl,
          },
          {
            headers: {
              authorization: `Bearer ${v.token}`,
            },
          }
        )
        .then(x => getCompanyInformationWithiScraperSchema.parse(x.data));

      return result;
    } catch {
      return "";
    }
  }

  public static addCompanyFromLinkedinRecommendation(v: {
    linkedinId: string;
    linkedinUrl: string;
    companyName: string;
    industryName: string | null;
    token: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/AddCompanyFromLinkedinRecommendation`,
        {
          linkedinId: v.linkedinId,
          linkedinUrl: v.linkedinUrl,
          companyName: v.companyName,
          industryName: v.industryName,
        },
        {
          headers: {
            authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }
}
