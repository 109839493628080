const runGritifyApiOnTest = false;
const runPublicOnTest = true;
const runSourcingRobotOnTest = true;

export const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runGritifyApiOnTest
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export const publicApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://public.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://public-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runPublicOnTest
    ? "https://gritify-publicapi-test.azurewebsites.net/api"
    : "http://localhost:7072/api";

export const sourcingRobotApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://sourcingrobot.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runSourcingRobotOnTest
    ? "https://gritify-sourcingrobot-test.azurewebsites.net/api"
    : "http://localhost:7073/api";
